import React from "react";

import "./SkipLink.scss";

export const SkipLink = () => {
  return (
    <a
      className="skip-link"
      href="#main-content"
      onKeyDown={(e) => {
        if (e.key === " ") {
          // Prevent space to scroll. That's annoying...
          e.preventDefault();
          document.getElementById("main-content")?.focus();
        }
      }}
    >
      Skip to main content
    </a>
  );
};
