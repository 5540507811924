import cx from "classnames";
import React from "react";
import "./FullWidth.scss";

export const FullWidth = ({
  as: Component = "div",
  children,
  outerClassName,
  innerClassName,
  ...rest
}) => {
  return (
    <Component
      className={cx("full-width--container", outerClassName)}
      {...rest}
    >
      <div className={cx("full-width--inner", innerClassName)}>{children}</div>
    </Component>
  );
};
