import { Link } from "gatsby";
import * as React from "react";
import { FullWidth } from "../fullWidth/FullWidth";
import { SkipLink } from "../skipLink/SkipLink";
import Helmet from "react-helmet";

import "./Layout.scss";

// Normally these would be baked into the gatsby config, but w/e.
const title = "RHL Consulting";
const description =
  "RHL Consulting. We strive to help you build a stronger, more equitable organization";
const siteUrl = "https://rhlconsulting.com";

export const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        title="RHL Consulting"
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={siteUrl} />
      </Helmet>
      <SkipLink />
      <FullWidth as="header" outerClassName="header--outer">
        <div className="header--root" id="page-top" tabIndex={-1}>
          <div className="header--left">
            {/* If a keyboard user is focusing this, they don't need to nav to the top. */}
            <Link to="/" className="header--logo--text" tabIndex={-1}>
              RHL Consulting
            </Link>
          </div>
          <nav className="header--right">
            {/* 
              TODO feels a bit weird we don't have any way of helping people understand where they are on the site 
              TODO can we give them an active URL? Do we need to change the IA?
            */}
            {/* TODO: use @reach/router's navigate to move focus here?
              https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#recommendations-for-programmatic-in-app-navigation
            */}
            <Link to="/#mission" className="header--link">
              Our Mission
            </Link>
            <Link to="/#services" className="header--link">
              Services
            </Link>
            <Link to="/team" className="header--link">
              Meet the team
            </Link>
            <Link to="/#contact" className="header--link">
              Contact
            </Link>
          </nav>
        </div>
      </FullWidth>
      <main id="main-content" tabIndex={-1}>
        {children}
      </main>
      <FullWidth
        as="footer"
        outerClassName="footer--root-outer"
        innerClassName="footer--root-inner"
      >
        <div>Seattle, Washington</div>
      </FullWidth>
    </>
  );
};
